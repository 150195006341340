import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Sidebar from '~components/sidebar'
import Breadcrumbs from '~components/detail/breadcrumbs'
import Login from '~components/detail/login'
import Recommended from '~components/detail/recommended'
import Resource from '~components/detail/resource'
import ShareButton from '~components/detail/share-button'
import { Bold, Italic, Tags } from '~components/shared'

const ServiceDetail = props => {
  const { section, locale } = props.pageContext
  const { location } = props

  const { serviceDetail, sidebarContent } = props.data
  const { showFeaturedVideo, showFeaturedActivities } = serviceDetail

  const tags = serviceDetail.secondaryTopics || []

  let name = ''
  let headline = ''
  let subline = ''
  let services = ''

  if (serviceDetail.name) {
    name = serviceDetail.name
  }

  if (serviceDetail.headline) {
    headline = serviceDetail.headline
  }

  if (serviceDetail.subline) {
    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
      }
    }

    subline = documentToReactComponents(serviceDetail.subline.json, options)
  }

  const servicesRef = serviceDetail.servicesRef

  if (servicesRef.length > 0) {
    services = servicesRef.map(service => {
      const serviceImage = {
        fixed: { ...service.logo.fixed },
        style: {
          objectFit: 'contain',
          objectPosition: 'top'
        }
      }

      const image = (
        <Image
          fixed={serviceImage.fixed}
          className='max-w-full max-h-full'
          imgStyle={serviceImage.style}
          alt='N/A'
          aria-hidden
        />
      )

      const options = {
        renderMark: {
          [MARKS.BOLD]: text => <Bold>{text}</Bold>,
          [MARKS.ITALIC]: text => <Italic>{text}</Italic>
        },
        renderNode: {
          [BLOCKS.HEADING_3]: (node, children) => <div className='mt-8 font-bold text-2xl'>{children}</div>,
          [BLOCKS.PARAGRAPH]: (node, children) => <div className='mt-4 body-4'>{children}</div>
        }
      }
      const description = documentToReactComponents(service.description.json, options)

      return (
        <Resource
          key={service.id}
          name={service.name}
          website={service.website}
          phone={service.phone}
          description={description}
          image={image}
        />
      )
    })
  }

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO
        lang={locale}
        title={serviceDetail?.metaTitle || `Find Help: ${name}`}
        description={serviceDetail?.metaDescription || headline}
        image={serviceDetail.image.file.url}
      />
      <div>
        <div className='max-w-page pt-4 lg:py-8'>
          <Breadcrumbs title={name} type='Service' />
        </div>
        <hr className='accent-rule' aria-hidden />
        <div className='flex flex-col lg:flex-row max-w-page py-8'>
          <div className='w-full lg:w-3/4 pr-4'>
            <div className='headline-1'>
              <FormattedMessage id='find-help' />: {name}
            </div>
            <div className='py-4'>
              <hr className='accent-rule' aria-hidden />
              <div className='flex flex-col lg:flex-row justify-between lg:items-center py-4'>
                <Tags tags={tags} />
                <div>
                  <ShareButton label='share-page' />
                </div>
              </div>
              <hr className='accent-rule' aria-hidden />
            </div>
            <div className='intro my-4 lg:my-8'>{headline}</div>
            <div className='body-4 my-8'>{subline}</div>
            <hr className='accent-rule' aria-hidden />
            {services}
            <div className='flex justify-end items-center py-4'>
              <div>
                <ShareButton label='share-prompt' />
              </div>
            </div>
            <hr className='accent-rule' aria-hidden />
            <Login />
            <Recommended />
          </div>
          <Sidebar
            sidebarContent={sidebarContent}
            showFeaturedVideo={showFeaturedVideo ?? true}
            showFeaturedActivities={showFeaturedActivities ?? true}
          />
        </div>
      </div>
    </Layout>
  )
}

export default ServiceDetail

export const pageQuery = graphql`
  query serviceDetailPageQuery($id: String!) {
    serviceDetail: contentfulServiceDetailPage(id: { eq: $id }) {
      name
      metaTitle
      metaDescription
      secondaryTopics {
        name
      }
      image {
        file {
          url
        }
      }
      headline
      subline {
        json
      }
      servicesRef {
        id
        name
        website
        phone
        description {
          json
        }
        logo {
          fixed {
            ...GatsbyContentfulFixed
          }
        }
      }
      showFeaturedVideo
      showFeaturedActivities
    }
    sidebarContent: contentfulServiceDetailPage(id: { eq: $id }) {
      pageFeaturedDownloadLabel: featuredDownloadLabel
      pageFeaturedDownloadTitle: featuredDownloadTitle
      pageFeaturedDownloadImage: featuredDownloadImage {
        file {
          url
        }
      }
      pageFeaturedDownloadAsset: featuredDownloadAsset {
        file {
          fileName
          url
        }
      }
      pageFeaturedDownloadLink: featuredDownloadLink
      pageFeaturedDownloadCta: featuredDownloadCta
      pageFeaturedVideo: featuredVideo {
        title
        contentful_id
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedService: featuredService {
        name
        headline
        slug
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedActivities: featuredActivities {
        title
        slug
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedQuote: featuredQuote {
        quote {
          quote
        }
        person
        personSubline
      }
    }
  }
`
