import React from 'react'
import { FormattedMessage } from 'react-intl'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { prettifyURL } from '~utilities'

const Resource = ({ image, name, website, phone, description }) => {
  return (
    <div className='flex flex-col lg:flex-row py-8'>
      <div className='w-48 h-48 overflow-hidden'>{image}</div>
      <div className='flex flex-col w-full lg:pl-8'>
        <div className='headline-2'>{name}</div>
        <div className='flex flex-col md:flex-row w-full md:-mx-4 mt-4'>
          {website && (
            <div className='md:w-3/5 md:px-4'>
              <div className='extended text-gray-dark'>
                <FormattedMessage id='website' />:
              </div>
              <a
                href={website}
                target='_blank'
                rel='noopener noreferrer'
                className='block mt-2 font-bold text-blue tracking-wider'
              >
                {website |> prettifyURL}
              </a>
            </div>
          )}
          {phone && (
            <div className='md:w-2/5 md:px-4 mt-4 md:mt-0'>
              <div className='extended text-gray-dark'>
                <FormattedMessage id='phone' />:
              </div>
              <a href={`tel:${phone}`} className='block mt-2 font-bold text-blue tracking-wider'>
                {phone}
              </a>
            </div>
          )}
        </div>
        <div>{description}</div>
      </div>
    </div>
  )
}

export default Resource
