import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useAtom } from 'jotai'
import { useIntl, FormattedMessage } from 'react-intl'

import { userMenuAtom } from '~atoms'
import { getUser } from '~utilities'

import { Button } from '~components/shared'

const Login = () => {
  const { locale } = useIntl()
  const data = useStaticQuery(staticQuery)
  const settings = data.settings.nodes.find(({ node_locale }) => node_locale === locale)
  const { signInTitle, signInMessage } = settings

  const [isUserMenuOpen, setIsUserMenuOpen] = useAtom(userMenuAtom)
  const user = getUser()

  if (!user) {
    return (
      <div className='my-8 p-8 bg-gray-light'>
        <div className='headline-3 font-bold'>{signInTitle}</div>
        <div className='body-4 mt-4'>{signInMessage}</div>
        <div className='mt-4'>
          <Button onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}>
            <FormattedMessage id='log-in-sign-up' />
          </Button>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Login

const staticQuery = graphql`
  query {
    settings: allContentfulSettings(filter: { title: { eq: "Global Settings" } }) {
      nodes {
        node_locale
        signInTitle
        signInMessage
      }
    }
  }
`
